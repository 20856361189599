<template lang="">
  <base-layout>
    <VehicleModelIndex
      fstId="manufacture-vehicle-model-index"
      :endpoint="getUrl"
      :qso="{ append: '', prepend: '&' }"
    />
  </base-layout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout'
import VehicleModelIndex from '@/views/vehicle-manufacturers/VehicleModelIndex.vue'
import { VehicleModelConfig } from '@/config/VehicleModelConfig'
export default {
  name: 'VehicleManufacturersDetails',
  components: {
    BaseLayout,
    VehicleModelIndex,
  },
  computed: {
    getUrl() {
      return VehicleModelConfig.api.index(this.$route.params.id)
    },
  },
}
</script>
<style lang=""></style>
