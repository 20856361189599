var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('VehicleManufactureAddEdit',{on:{"add-edit":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}}),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10 md:grid-cols-2"},[_c('TitlePlus',{attrs:{"title":"Vehicle Manufacturer","hide-plus":false},on:{"plus":_vm.add}})],1),_c('div',{staticClass:"summary-card-container"},[_c('SummaryCard',{attrs:{"title":"Total ","value":_vm.indexMetaData.count.total,"variant":"gray","size":"large"}})],1)]),_c('FSTable',{attrs:{"fst-id":"manufactureIndex","searchEnabled":false,"headers":_vm.getTableHeaders,"endpoint":_vm.indexDataEndpoint},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [(_vm.getTableMode === 'full')?[_c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5,"to":{
                  name: 'VehicleManufacturersDetails',
                  params: { id: item.id },
                }}}),_c('FSTableRowItem',{attrs:{"text":item.name}}),_c('FSTableRowItem',{attrs:{"text":_vm.getCountryName(item)}}),_c('FSTableRowItem',{attrs:{"text":_vm.getVehicleType(item)}}),_c('FSTableRowItem',[_c('div',{staticClass:"flex items-center"},[_c('oto-edit-icon',{on:{"click":function($event){return _vm.edit(item)}}})],1)])],1)]:_vm._e(),(_vm.getTableMode === 'responsive')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5,"to":{
                  name: 'VehicleManufacturersDetails',
                  params: { id: item.id },
                }}}),_c('FSTableRowItem',{attrs:{"text":item.name}}),_c('FSTableRowItem',[_c('div',{staticClass:"flex items-center"},[_c('oto-edit-icon',{on:{"click":function($event){return _vm.edit(item)}}})],1)])],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Headquarter Location")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getCountryName(item))+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Vehicle Class")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getVehicleType(item))+" ")])])])]):_vm._e()]:_vm._e()]})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }