<template>
  <base-layout>
    <VehicleManufactureAddEdit
      @add-edit="$store.dispatch('fsTable/fetchData')"
    />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus
            title="Vehicle Manufacturer"
            @plus="add"
            :hide-plus="false"
          />
        </div>
        <div class="summary-card-container">
          <SummaryCard
            title="Total "
            :value="indexMetaData.count.total"
            variant="gray"
            size="large"
          />
        </div>
      </div>

      <FSTable
        :fst-id="`manufactureIndex`"
        :searchEnabled="false"
        :headers="getTableHeaders"
        :endpoint="indexDataEndpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" text-fallback-always>
                <FSTableRowItem
                  :text="item.id"
                  :truncate="-5"
                  :to="{
                    name: 'VehicleManufacturersDetails',
                    params: { id: item.id },
                  }"
                />

                <FSTableRowItem :text="item.name" />
                <FSTableRowItem :text="getCountryName(item)" />
                <FSTableRowItem :text="getVehicleType(item)" />
                <FSTableRowItem
                  ><div class="flex items-center">
                    <oto-edit-icon @click="edit(item)" /></div
                ></FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  :text="item.id"
                  :truncate="-5"
                  :to="{
                    name: 'VehicleManufacturersDetails',
                    params: { id: item.id },
                  }"
                />
                <FSTableRowItem :text="item.name" />
                <FSTableRowItem
                  ><div class="flex items-center">
                    <oto-edit-icon @click="edit(item)" /></div
                ></FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">Headquarter Location</div>
                    <div class="col-span-5 right-text">
                      {{ getCountryName(item) }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">Vehicle Class</div>
                    <div class="col-span-5 right-text">
                      {{ getVehicleType(item) }}
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import VehicleManufactureAddEdit from '@/views/vehicle-manufacturers/VehicleManufactureAddEdit.vue'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import { VehicleManufactureConfig } from '@/config/VehicleManufactureConfig'
import { EventBus } from '@/utils'
import { useCountryIndex } from '@/composables'
import SummaryCard from '@/components/cards/SummaryCard'
export default {
  name: 'VehicleManufacturersIndex',
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    VehicleManufactureAddEdit,
    SummaryCard,
  },
  data() {
    return {
      indexMetaData: {
        count: {
          total: 0,
        },
      },
      indexDataEndpoint: VehicleManufactureConfig.api.index(),
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        { text: 'ID', width: '10%', sort: 'id' },
        { text: 'Manufacturer Name', width: '30%', sort: 'null' },
        { text: 'Headquarter Location', width: '30%', sort: 'null' },
        { text: 'Vehicle Class', width: '20%', sort: 'null' },
        { text: 'Actions', width: '10%', sort: 'null' },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        { text: 'ID', width: '10%', sort: null },
        { text: 'Manufacturer Name', width: '50%', sort: null },
        { text: 'Actions', width: '10%', sort: null },
      ],
      countries: [],
    }
  },
  async created() {
    this.countries = await useCountryIndex()
      .then(({ data }) => data)
      .catch((e) => console.log(e))
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getCountryName(item) {
      return item?.country?.name ?? '--'
    },
    getVehicleType(item) {
      // return this.vehicleTypeMap[item.vehicle_class].text
      return item.vehicle_classes.length > 0
        ? item.vehicle_classes.join(', ')
        : '--'
    },
    add() {
      EventBus.$emit(VehicleManufactureConfig.events.editingData, {
        countries: this.countries,
        item: {},
      })
      dispatchEvent(new Event(VehicleManufactureConfig.events.sorToggle))
    },
    edit(item) {
      console.log(item)
      EventBus.$emit(VehicleManufactureConfig.events.editingData, {
        item: item,
        countries: this.countries,
      })
      dispatchEvent(new Event(VehicleManufactureConfig.events.sorToggle))
    },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
